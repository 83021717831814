import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";

const SocialMediaIcons = () => {
	return (
		<div className="social-media-icons">
			<a
				href="https://www.facebook.com/people/Janelle-Williams-MSW-RSW/61555261923785/"
				target="_blank"
				rel="noopener noreferrer"
				className="facebook"
			>
				<FontAwesomeIcon icon={faFacebook} size="2x" />
			</a>

			<a
				href="https://www.instagram.com/janellewilliamsrsw/?fbclid=IwAR1qP67jAv-XpNwpMT4xJwTKy41t_C_RksXTX1L9gybCF25in46RxUFfYDw"
				target="_blank"
				rel="noopener noreferrer"
				className="instagram"
			>
				<FontAwesomeIcon icon={faInstagram} size="2x" />
			</a>

			<a
				href="mailto:janellewilliamscounselling@gmail.com"
				target="_blank"
				rel="noopener noreferrer"
				className="email"
			>
				<FontAwesomeIcon icon={faEnvelope} size="2x" />
			</a>
		</div>
	);
};

export default SocialMediaIcons;
