import React from "react";
import { Link, useNavigate } from "react-router-dom";
import SocialMediaIcons from "./SocialMedia";
import logoNoBackground from "../assets/images/logoNoBackground.png";

const Footer = () => {
	const navigate = useNavigate();

	const handleLogoClick = () => {
		navigate("/");
	};

	return (
		<div className="footer">
			<SocialMediaIcons />
			<img
				src={logoNoBackground}
				alt="company logo"
				className="navigation-logo"
				onClick={handleLogoClick}
				style={{ cursor: "pointer" }}
			/>
			<nav>
				<ul className="footerNav">
					<li>
						<Link to="/">Home</Link>
					</li>
					<li>
						<Link to="/about">About</Link>
					</li>
					<li>
						<Link to="/services">Services</Link>
					</li>
					<li>
						<Link to="/contact">Contact</Link>
					</li>
				</ul>
			</nav>
		</div>
	);
};

export default Footer;
