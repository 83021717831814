import React from "react";

const MapComponent = () => {
	return (
		<div style={{ width: "100%", height: "400px" }}>
			<iframe
				src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2692.2608486183053!2d-52.776325923609505!3d47.562712971185825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4b0ca48346d61e75%3A0x7341791b6baa520b!2s6%20Duffy%20Pl%2C%20St.%20John&#39;s%2C%20NL%20A1B%204M5!5e0!3m2!1sen!2sca!4v1719421817992!5m2!1sen!2sca"
				width="100%"
				height="100%"
				style={{ border: 0 }} // Corrected the style prop
				allowFullScreen // Corrected property name
				loading="lazy"
				referrerPolicy="no-referrer-when-downgrade" // Corrected property name
			></iframe>
		</div>
	);
};

export default MapComponent;
