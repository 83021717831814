import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import logoNoBackground from "../assets/images/logoNoBackground.png";

const Navigation = () => {
	const [menuOpen, setMenuOpen] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const menuRef = useRef(null);

	const handleLogoClick = () => {
		navigate("/");
	};

	const handleBookNowClick = () => {
		navigate("/contact");
	};

	const toggleMenu = () => {
		setMenuOpen(!menuOpen);
	};

	const handleClickOutside = (event) => {
		if (menuRef.current && !menuRef.current.contains(event.target)) {
			setMenuOpen(false);
		}
	};

	useEffect(() => {
		if (menuOpen) {
			document.addEventListener("mousedown", handleClickOutside);
		} else {
			document.removeEventListener("mousedown", handleClickOutside);
		}

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [menuOpen]);

	return (
		<div className="navigation-container">
			<img
				src={logoNoBackground}
				alt="company logo"
				className="navigation-logo"
				onClick={handleLogoClick}
				style={{ cursor: "pointer" }}
			/>
			<div className="nav-links">
				<nav>
					<ul>
						<li className={location.pathname === "/" ? "active" : ""}>
							<Link to="/">Home</Link>
						</li>
						<li className={location.pathname === "/about" ? "active" : ""}>
							<Link to="/about">About</Link>
						</li>
						<li className={location.pathname === "/services" ? "active" : ""}>
							<Link to="/services">Services</Link>
						</li>
						<li className={location.pathname === "/contact" ? "active" : ""}>
							<Link to="/contact">Contact</Link>
						</li>
					</ul>
				</nav>
			</div>
			<div className="hamburger-icon" onClick={toggleMenu}>
				<FontAwesomeIcon icon={menuOpen ? faTimes : faBars} size="lg" />
			</div>
			{menuOpen && (
				<div className="menu-overlay">
					<nav className="nav-open" ref={menuRef}>
						<ul>
							<li className={location.pathname === "/" ? "active" : ""}>
								<Link to="/" onClick={toggleMenu}>
									Home
								</Link>
							</li>
							<li className={location.pathname === "/about" ? "active" : ""}>
								<Link to="/about" onClick={toggleMenu}>
									About
								</Link>
							</li>
							<li className={location.pathname === "/services" ? "active" : ""}>
								<Link to="/services" onClick={toggleMenu}>
									Services
								</Link>
							</li>
							<li className={location.pathname === "/contact" ? "active" : ""}>
								<Link to="/contact" onClick={toggleMenu}>
									Contact
								</Link>
							</li>
							<li className="book-now-mobile">
								<button
									onClick={() => {
										handleBookNowClick();
										toggleMenu();
									}}
								>
									Book Now
								</button>
							</li>
						</ul>
					</nav>
				</div>
			)}
			<div className="book-now-desktop">
				<button onClick={handleBookNowClick}>Book Now</button>
			</div>
		</div>
	);
};

export default Navigation;
