// src/index.js
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import "./index.css";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
	<React.StrictMode>
		<GoogleReCaptchaProvider reCaptchaKey="6LeTHg0qAAAAAKp6T1NGw5AdoSnXq4ImKcTApdui">
			<App />
		</GoogleReCaptchaProvider>
	</React.StrictMode>
);
