import { type } from "@testing-library/user-event/dist/type";
import React, { Children } from "react";
import { useNavigate } from "react-router-dom";

const Button = ({ to, onClick, type = "button", children }) => {
	const navigate = useNavigate();

	const handleClick = () => {
		if (to) {
			navigate(to);
		} else if (onClick) {
			onClick();
		}
	};

	return (
		<button type={type} onClick={handleClick}>
			{children}
		</button>
	);
};
export default Button;
