import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import MapComponent from "../components/Map";
import Janelle from "../assets/images/Janelle.JPG";
import waitingRoom from "../assets/images/waitingRoom.JPG";

const HomePage = () => {
	const navigate = useNavigate();

	const handleGetStartedClick = () => {
		navigate("/contact");
		setTimeout(() => {
			window.scrollTo(0, 0); // Scroll to the top of the contact page
		}, 0);
	};

	return (
		<div className="home-page">
			<div className="section-about-home gray">
				<h1>Welcome to Janelle Williams Counselling</h1>
				<p>
					Janelle is a dedicated and experienced registered social worker,
					committed to helping you achieve your therapeutic goals.
				</p>
				<img src={Janelle} alt="Picture of Janelle" className="homePicture" />
			</div>
			<div className="section-approach-home">
				<h1>My Approach</h1>
				<p>
					Janelle's approach to therapy is rooted in the belief that all people
					are capable and deserving of achieving their therapeutic goals. She
					emphasizes collaboration within the therapeutic relationship and
					values the unique experiences and perspectives of everyone she works
					alongside.
				</p>
				<Button className="button" to="/about">
					Learn More
				</Button>
			</div>
			<div className="section gray home-grid-container section-about-home">
				<div className="home-new-clients-info">
					<h1>Now Accepting New Clients</h1>
					<p>
						Janelle is currently accepting new clients for in-person counselling
						in St. John's and Zoom or phone counselling across Newfoundland and
						Labrador. New referrals are being accepted for both individual and
						couples counselling. Reach out today to arrange your free 15-minute
						phone consultation.
					</p>
				</div>
				<div className="home-waiting-room-image">
					<img
						src={waitingRoom}
						alt="Picture of Waiting Room"
						className="homePictureOffice"
					/>
				</div>
				<div className="home-map">
					<MapComponent />
				</div>
				<div className="home-in-person-remote-options">
					<h1>In-person and Remote Options</h1>
					<p>
						The office is conveniently located at 6 Duffy Place, Suite A, St.
						John's, Newfoundland & Labrador. Can't make it to the office? No
						worries – virtual Zoom or phone sessions are available.
					</p>
					<Button
						className="button"
						to="/contact"
						onClick={handleGetStartedClick}
					>
						Get Started
					</Button>
				</div>
			</div>
		</div>
	);
};

export default HomePage;
